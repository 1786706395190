<template>
  <v-container fluid>
    <v-tabs v-model="tab_details">
      <v-tab dense :value="0">ARTÍCULOS</v-tab>
      <v-tab dense :value="1">ESCANDALLOS</v-tab>
      <v-tab dense :value="2">RECETAS</v-tab>
      <v-tab dense :value="3">MENÚS</v-tab>
      <v-spacer></v-spacer>
      <v-tab dense :value="4">FAMILIAS</v-tab>
      <v-tab dense :value="5">CATEGORÍAS</v-tab>
    </v-tabs>
    <!-- @click="rel('fam')"
@click="rel('art')"
@click="rel('esc')"
@click="rel('rec')"
@click="rel('men')" -->
    <v-tabs-items
      style="font-size: 0.9rem;flex-grow: 1;"
      class="py-1"
      v-model="tab_details"
    >
      <v-tab-item :value="0">
        <v-card class="mb-6">
          <v-card-title class="primary--text text-h4 font-weight-bold">ARTÍCULOS</v-card-title>
          <ListadoArticulos @reload="reload" :loading="loading" :familias="familias" :articulos="articulos" ref="art"/>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="1">
        <v-card class="mb-6">
          <v-card-title class="primary--text text-h4 font-weight-bold">ESCANDALLOS</v-card-title>
          <ListadoEscandallos @reload="reload" :loading="loading" :familias="familias" :articulos="articulos" :escandallos="escandallos" ref="esc"/>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="2">
        <v-card class="mb-6">
          <v-card-title class="primary--text text-h4 font-weight-bold">RECETAS</v-card-title>
          <ListadoRecetas @reload="reload" :loading="loading" :familias="familias" :categorias="categorias" :articulos="articulos" :escandallos="escandallos" :recetas="recetas" ref="rec" :biggestItem="biggestItem" :recetasHeader="recetasHeader"/>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="3">
        <v-card class="mb-6">
          <v-card-title class="primary--text text-h4 font-weight-bold">MENÚS</v-card-title>
          <ListadoMenus @reload="reload" :loading="loading" :familias="familias" :categorias="categorias" :articulos="articulos" :escandallos="escandallos" :recetas="recetas" :menus="menus" ref="men"/>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="4">
        <v-card class="mb-6">
          <v-card-title class="primary--text text-h4 font-weight-bold">FAMILIAS</v-card-title>
          <ListadoFamilias @reload="reload" :loading="loading" :familias="familias" ref="fam"/>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="5">
        <v-card class="mb-6">
          <v-card-title class="primary--text text-h4 font-weight-bold">CATEGORÍAS</v-card-title>
          <ListadoCategorias @reload="reload" :loading="loading" :categorias="categorias" ref="fam"/>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
export default {
  components: {
    ListadoFamilias: () => import("../components/familias/Familias.vue"),
    ListadoCategorias: () => import("../components/categorias/Categorias.vue"),
    ListadoArticulos: () => import("../components/articulos/Articulos.vue"),
    ListadoEscandallos: () => import("../components/escandallos/Escandallos.vue"),
    ListadoRecetas: () => import("../components/recetas/Recetas.vue"),
    ListadoMenus: () => import("../components/menus/Menus.vue"),
  },
  data(){
    return {
      tab_details: 0,
      value: 0,
      keyHelper: 0,
      loading: true,
      recetasHeader: [],
      biggestItem: 0,
      familias: [],
      categorias: [],
      articulos: [],
      escandallos: [],
      recetas: [],
      menus: [],

    }
  },
  methods:{
    async reload(){
      this.loading = true
      await Promise.all([
        this.getFamilias(),
        this.getCategorias(),
        this.getArticulos(),
        this.getEscandallos(),
        this.getRecetas(),
        this.getMenus()
      ])
      this.loading = false
    },
    async getArticulos(){
      this.keyHelper++;
      const { data } = await axios({
        url: "/articulos",
      });
      this.articulos = data;
      this.articulos.forEach(art => {
        art.actualizado = new Date(art.actualizado)
      });
    },
    async getFamilias(){
      this.keyHelper++;
      const { data } = await axios({
        url: "/familias",
      });
      this.familias = data;
    },
    async getCategorias(){
      this.keyHelper++;
      const { data } = await axios({
        url: "/categorias",
      });
      this.categorias = data;
    },
    async getEscandallos(){
      this.keyHelper++;
      const { data } = await axios({
        url: "/escandallos",
      });
      this.escandallos = data;
      this.escandallos.forEach(esc => {
        esc.actualizado = new Date(esc.actualizado)
        esc.familiasShow = this.familias.filter(fam => esc.familias.includes(fam.idFamilia)).map(f => f.nombre).join(' / ')
      });
    },
    async getRecetas(){
      this.keyHelper++;
      const { data } = await axios({
        url: "/recetas",
      });
      this.recetas = data;
      
      this.recetas.forEach(rec => {
        rec.actualizado = new Date(rec.actualizado)
        rec.categoriasShow = this.categorias.filter(cat => rec.categorias.includes(cat.idCategoria)).map(f => f.nombre).join(' / ')
        if (rec.escandallos.length > this.biggestItem) this.biggestItem = rec.escandallos.length
      });

      var aux = [
        { text: "Nº", value: "n", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Nombre", value: "nombre", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Categoría", value: "categoriasShow", cellClass: "pa-1 text-no-wrap font-weight-bold" }
      ]
      for (let i = 1; i < this.biggestItem+1; i++) {
        aux.push({ text: "Art" + i, value: "art"+i, cellClass: "pa-1 text-no-wrap font-weight-bold text-center" })
      }
      aux.push({ text: "Precio", value: "precio", cellClass: "pa-1 text-no-wrap font-weight-bold text-center" })
      aux.push({ text: "Nota", value: "nota", cellClass: "pa-1 text-no-wrap font-weight-bold" })
      aux.push({ text: "Actualizado", value: "actualizado", cellClass: "pa-1 text-no-wrap font-weight-bold" })
      aux.push({ text: "Acciones", value: "acciones", sortable: false, filterable: false })
      this.recetasHeader = aux
    },
    async getMenus(){
      this.keyHelper++;
      const { data } = await axios({
        url: "/menus",
      });
      this.menus = data;
      this.menus.forEach(menu => {
        if(isNaN(menu.desviacion)) menu.desviacion = 5
        menu.creado = new Date(menu.creado)
        menu.precio = 0
        menu.recetas.forEach(rec => {
          menu.precio += rec.precio
        });
        menu.precioDesv = (menu.precio * (1+(menu.desviacion/100)) / menu.ratioFnB) * 100
      });
    },
  },
  mounted(){
    this.reload()
  }
};
</script>
